import { setBackendUrl, setAuthUrl, setDevMode, validateCompliance, validateUser } from '@securely.id/websdk';
import { ComplianceAbi } from './ethereum/contracts/abis/compliance.js';
import { CompliantTreasuryAbi } from './ethereum/contracts/abis/compliant-treasury.js';
import { getControllers } from './ethereum/contracts/compliance.js';
import { Erc20Abi } from './ethereum/contracts/abis/erc20.js';
import { addresses } from './ethereum/contracts/addresses.js';
import { prettyAddress } from './ethereum/utils.js';
import { getChainId, getSigner, getReadonlyProvider, providerInit, onWalletConnect } from './ethereum/wallet.js';
import { loadingSpinner, formatTimestamp, refreshTooltips } from './utils.js';
const ethers = require('ethers');

const etherscanIconUrl = require('/src/assets/images/etherscan-logo-circle.svg');

var decimals = { '0x0000000000000000000000000000000000000000': 18 };
var balances = {};
var compliantBalances = {};
var currency;
var whitelisted = false;
const defaultCurrencies = ['USDC', 'RWA'];

function auditLink(chainID, verificationReferenceID, display) {
    return `<a href="#" onClick="AuditRequest=window.open('https://app.securely.id/audit/?chain-id=${chainID}&verification-reference-id=${verificationReferenceID}','AuditRequest','width=450,height=560'); return false;">${display}</a>`;
}

function updateCurrencyIcon(currency) {
    switch (currency) {
        case 'ETH':
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/ETH.svg'));
            break;
        case 'MATIC':
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/MATIC.svg'));
            break;
        case 'USDC':
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/USDC.svg'));
            break;
        default:
            $('.currency-icon').attr('src', require('/src/assets/images/currencies/undefined.svg'));
            break;
    }
}

async function templateInjection() {
    updateLink();

    // Fetch the base informations
    console.log('Fetching base informations...');
    const signer = await getSigner();
    $('#wallet-address').html(prettyAddress(signer.address));
    const readonlyProvider = await getReadonlyProvider();
    const chainId = await getChainId();
    const etherscan = addresses[chainId].getAddress('etherscan');
    const dappAddress = addresses[chainId].getAddress('compliantTreasury');
    $('#dapp-contract-address').html(`<a href="${etherscan}/address/${dappAddress}">${dappAddress}</a>`);
    $('#policies-explorer-link').attr('href', `https://dashboard.securely.id/explorer/?chainid=${chainId}&address=${dappAddress}`);
    const compliantTreasury = new ethers.Contract(dappAddress, CompliantTreasuryAbi, readonlyProvider);
    const complianceAddress = await compliantTreasury.compliance();
    $('#compliance-contract-address').html(`<a href="${etherscan}/address/${complianceAddress}">${complianceAddress}</a>`);
    const compliance = new ethers.Contract(complianceAddress, ComplianceAbi, readonlyProvider);
    try {
        const whitelistedRole = await compliantTreasury.WHITELISTED_ROLE();
        whitelisted = await compliantTreasury.hasRole(whitelistedRole, signer.address);
    } catch (error) {
        console.warn('Failed to check whitelisted role:', error.message);
    }
    if (whitelisted) {
        $('#principal-title').text('Compliant');
        $('#superficial-title').text('Unverified');
        $('#deposit-block').hide();
        $('#payment-link-label').text('Share this link:');
    } else {
        $('#principal-title').text('My Vault');
        $('#superficial-title').text('Connected Wallet');
        $('#superficial-col').hide();
        $('#principal-col').removeClass('col-8').addClass('col-12');
        $('#payment-link-label').text('Want someone else to deposit? Share this link:');
    }

    // Display the compliance details
    const controllers = await getControllers(compliance);
    const controllersLineBreak = Object.keys(controllers).length > 1 ? '<br />' : '';
    $('#compliance-controllers').empty();
    for (const controller in controllers)
        $('#compliance-controllers').append(`${controllersLineBreak}<a href="${etherscan}/address/${controller}">${controller}</a>`);

    // Build the movements in three steps: payments, withdrawals, and transfers
    console.log("Building movements...");
    const movements = [];

    // Step 1: payments
    console.log("Step 1: payments");
    const paymentsPromise = compliantTreasury.queryFilter(compliantTreasury.filters.Payment(null, signer.address));
    const payments = (await paymentsPromise).reverse();
    for (const payment of payments) {
        const { source, destination, currency, amount } = payment.args;
        payment.type = "payment";
        movements.push({
            blockNumber: payment.blockNumber,
            type: "payment",
            source,
            destination,
            currency,
            amount,
            txHash: payment.transactionHash
        });
    }

    // Step 2: withdrawals
    console.log("Step 2: withdrawals");
    const withdrawalsPromise = compliantTreasury.queryFilter(compliantTreasury.filters.Withdrawal(signer.address));
    const withdrawals = await withdrawalsPromise;
    for (const withdrawal of withdrawals) {
        const { account, currency, amount } = withdrawal.args;
        withdrawal.type = "withdrawal";
        movements.push({
            blockNumber: withdrawal.blockNumber,
            type: "withdrawal",
            source: account,
            destination: account,
            currency,
            amount,
            txHash: withdrawal.transactionHash
        });
    }

    // Step 3: transfers
    console.log("Step 3: transfers");
    // Create filters for transfers where either the first or the second argument is signer.address
    const transfersFromPromise = compliantTreasury.queryFilter(compliantTreasury.filters.Transfer(signer.address, null));
    const transfersFrom = await transfersFromPromise;
    const transfersToPromise = compliantTreasury.queryFilter(compliantTreasury.filters.Transfer(null, signer.address));
    const transfersTo = await transfersToPromise;

    // Combine the results from both filters
    const transfers = [...transfersFrom, ...transfersTo];

    // Remove duplicates if any
    const uniqueTransfers = Array.from(new Set(transfers.map(t => t.transactionHash)))
        .map(hash => transfers.find(t => t.transactionHash === hash));
    
    for (const transfer of uniqueTransfers) {
        const { source, destination, currency, amount } = transfer.args;
        movements.push({
            blockNumber: transfer.blockNumber,
            type: "transfer",
            source,
            destination,
            currency,
            amount,
            txHash: transfer.transactionHash
        });
    }

    // Build the default balances
    console.log("Building default balances...");
    balances = {
        '0x0000000000000000000000000000000000000000': await signer.provider.getBalance(signer.address)
    };
    compliantBalances = {
        '0x0000000000000000000000000000000000000000': await compliantTreasury.balanceOf(signer.address, ethers.ZeroAddress)
    };
    for (let prettyCurrency of defaultCurrencies) {
        const currency = addresses[chainId].getAddress(prettyCurrency);
        const erc20 = new ethers.Contract(currency, Erc20Abi, readonlyProvider);
        try {
            decimals[currency] = await erc20.decimals();
        }
        catch (error) {
            console.warn('Failed to get decimals (defaulting to 18):', error.message);
            decimals[currency] = 18;
        }
        try {
            balances[currency] = await erc20.balanceOf(signer.address);
            compliantBalances[currency] = await compliantTreasury.balanceOf(signer.address, currency);
        }
        catch (error) {
            console.warn(`Failed to get balance for ${prettyCurrency} (defaulting to 0):`, error.message);
            balances[currency] = BigInt(0);
            compliantBalances[currency] = BigInt(0);
        }
    }
    console.log("decimals", decimals);
    console.log("Building balances...");
    for (const movement of movements) {
        if (decimals[movement.currency] === undefined) {
            const erc20 = new ethers.Contract(movement.currency, Erc20Abi, readonlyProvider);
            try {
                decimals[movement.currency] = await erc20.decimals();
            } catch (error) {
                console.warn('Failed to get decimals (defaulting to 18):', error.message);
                decimals[movement.currency] = 18;
            }
        }
        if (balances[movement.currency] === undefined) {
            try {
                balances[movement.currency] = await erc20.balanceOf(signer.address);
            } catch (error) {
                console.warn(`Failed to get balance for ${movement.currency} (defaulting to 0):`, error.message);
                balances[movement.currency] = BigInt(0);
            }
        }
        if (compliantBalances[movement.currency] === undefined) {
            try {
                compliantBalances[movement.currency] = await compliantTreasury.balanceOf(signer.address, movement.currency);
            } catch (error) {
                console.warn(`Failed to get compliant balance for ${movement.currency} (defaulting to 0):`, error.message);
                compliantBalances[movement.currency] = BigInt(0);
            }
        }
    }

    // Display the balances table
    console.log("Displaying balances...");
    $('#balances').empty();
    $('#balances-details').empty();
    for (let token in compliantBalances) {
        var compliantBalance = compliantBalances[token];
        var unverifiedBalance = balances[token];
        if (whitelisted) {
            if (compliantBalance > balances[token])
                compliantBalance = balances[token];
            unverifiedBalance -= compliantBalance;
        }
        const prettyCurrency = addresses[chainId].getPrettyName(token, true, true, false);
        var prettyComplianceBalance = ethers.formatUnits(compliantBalance, decimals[token]);
        prettyComplianceBalance = (+prettyComplianceBalance).toFixed(4).replace(/\.0000$/, '');
        var prettyUnverifiedBalance = ethers.formatUnits(unverifiedBalance, decimals[token]);
        prettyUnverifiedBalance = (+prettyUnverifiedBalance).toFixed(4).replace(/\.0000$/, '');
        $('#balances').append(`<tr>
            <td>${prettyComplianceBalance}</td>
            <td>${prettyCurrency}</td>
            <td class="w-100"></td>
            <td class="nowrap">
                <a href="#" class="transfer" data-currency="${addresses[chainId].getPrettyName(token, false)}" data-bs-toggle="modal" data-bs-target="#transferModal"><i class="fa-solid fa-upload"></i></a>
                &nbsp;&nbsp;
                <a href="#" class="receive" data-currency="${addresses[chainId].getPrettyName(token, false)}" data-bs-toggle="modal" data-bs-target="#receiveModal"><i class="fa-solid fa-download"></i></a>
            </td>
        </tr>`);
        $('#balances-details').append(`<tr>
            <td class="w-50"></td>
            <td class="superficial">${prettyUnverifiedBalance}</td>
            <td class="superficial">${prettyCurrency}</td>
            <td class="w-50"></td>
        </tr>`);
    }
    $('.transfer').on('click', function() { prepareTransfer($(this).data('currency')); });
    $('.receive').on('click', function() { prepareReceive($(this).data('currency')); });

    // Check if the approvals column should be displayed
    console.log("Checking approvals...");
    const approvalsPromise = compliance.queryFilter(compliance.filters.ApprovalRequired(dappAddress));
    const approvals = (await approvalsPromise).reverse();
    if (approvals.length > 0)
        $('#approvals-column').show();

    // Build & display the payment history table
    console.log("Building payment history...");
    const sortedMovements = movements.sort((a, b) => b.blockNumber - a.blockNumber);
    $('#payment-history').empty();
    for (const movement of sortedMovements) {
        const { blockNumber, type, source, destination, currency, amount, txHash } = movement;
        const receipt = await readonlyProvider.getTransactionReceipt(txHash);
        const complianceEvents = receipt.logs.map(
            log => { try { return compliance.interface.parseLog(log) } catch (e) { return null; } }
        ).filter(event => event && event.name == "ComplianceConsumed");
        if (complianceEvents.length == 0) {
            console.warn(`No ComplianceConsumed event found for ${txHash}`);
            continue;
        }
        const complianceDetails = complianceEvents[0].args.complianceDetails;
        const complianceFullHash = complianceEvents[0].args.fullHash;
        const comData = JSON.parse(complianceDetails);
        const timestamp = (await readonlyProvider.getBlock(blockNumber)).timestamp;
        var richArgs = `
            <!--Compliance hash: ${auditLink(chainId, complianceFullHash)}<br />-->
            <!--Transaction hash: ${txHash}<br />-->
        `;
        for (const comSubData of comData) {
            richArgs += `<span data-bs-toggle="tooltip" data-bs-title="Ref: ${comSubData.ref}" data-bs-placement="top">
                ${auditLink(chainId, comSubData.ref, `${comSubData.type} (${comSubData.providerName})`)}
                </span><br />`;
        }
        var approvalDate = 'Automatic';
        if (approvals.length > 0) {
            const approvalVerdicts = await compliance.queryFilter(compliance.filters.ComplianceVerdict(dappAddress, complianceFullHash));
            if (approvalVerdicts.length > 0) {
                const approvalTimestamp = (await readonlyProvider.getBlock(approvalVerdicts[0].blockNumber)).timestamp;
                approvalDate = formatTimestamp(Number(approvalTimestamp));
            }
        }
        const color = (source == signer.address && type != 'payment') ? '#B00000' : 'green';
        const sign = (source == signer.address && type != 'payment') ? '-' : '+';
        const prettyAmount = ethers.formatUnits(amount, decimals[currency]).replace(/\.0$/, '');
        const prettyCurrency = addresses[chainId].getPrettyName(currency);
        var action;
        if (type == 'payment' && source == signer.address)
            action = 'deposited';
        else if (type == 'payment')
            action = 'payed by';
        else if (type == 'withdrawal')
            action = 'withdrawn';
        else if (type == 'transfer' && source == signer.address && destination == signer.address)
            continue;
        else if (type == 'transfer' && source == signer.address)
            action = 'sent to';
        else if (type == 'transfer' && destination == signer.address)
            action = 'received from';
        const counterpart = (action == 'deposited' || action == 'withdrawn') ?
            '' : prettyAddress(destination == signer.address ? source : destination, true, false);
        const approval = approvals.length > 0 ? ` (approved ${approvalDate})` : '';
        $('#payment-history').append(`<tr>
            <td style="color: ${color}" class="nowrap">${sign}</td>
            <td style="color: ${color}" class="nowrap">${prettyAmount}</td>
            <td style="color: ${color}" class="nowrap">${prettyCurrency}</td>
            <td class="nowrap">${action}</td>
            <td class="nowrap"><b>${counterpart}</b></td>
            <td class="nowrap">${formatTimestamp(Number(timestamp))}</td>
            <td class="nowrap">${approval}</td>
            <td class="nowrap"></td>
            <td class="nowrap">${richArgs}</td>
            <td class="nowrap"></td>
            <td class="nowrap">
                ${auditLink(chainId, txHash, '<i class="fa-solid fa-receipt" data-bs-toggle="tooltip" data-bs-html="true" data-bs-title="Request<br />compliance data" data-bs-placement="top"></i>')}&nbsp;
                <a href="${etherscan}/tx/${txHash}" target="_blank" data-bs-toggle="tooltip" data-bs-html="true" data-bs-title="View on<br />Etherscan">
                    <img height="20px" src="${etherscanIconUrl}" alt="View on Etherscan" />
                </a>
            </td>
        </tr>`);
    }

    // Refresh the UI elements
    refreshTooltips();

    console.log("Template injection done");
}

function errorPopup(message) {
    console.error('Error:', message);
    $('#error-toast-body').text(message);
    new bootstrap.Toast($('#error-toast')).show();
    $('#pay-button').prop('disabled', false);
    $('#pay-button').html('Pay');
}

function warningPopup(message) {
    console.warn('Warning:', message);
    $('#warning-toast-body').text(message);
    new bootstrap.Toast($('#warning-toast')).show();
    $('#pay-button').prop('disabled', false);
    $('#pay-button').html('Pay');
}

function successPopup(message) {
    console.info('Success:', message);
    $('#success-toast-body').text(message);
    new bootstrap.Toast($('#success-toast')).show();
}

function prepareTransfer(prettyCurrency) {
    currency = prettyCurrency;
    console.log('Transfer:', currency);
    updateCurrencyIcon(currency);
    $('.currency').text(prettyAddress(currency, false, false));
    $('#transfer-amount-input').val('');
    $('#transfer-recipient-input').val('');
}

function prepareReceive(prettyCurrency) {
    currency = prettyCurrency;
    console.log('Receive:', currency);
    updateCurrencyIcon(currency);
    updateLink();
    $('.currency').text(prettyAddress(currency, false, false));
    $('#receive-amount-input').val('');
}

async function transfer() {
    $('#transfer-button').prop('disabled', true);
    $('#transfer-button').html(loadingSpinner);
    const signer = await getSigner();
    const chainId = (await signer.provider.getNetwork()).chainId;
    const compliantTreasuryAddress = addresses[chainId].getAddress('compliantTreasury');
    const compliantTreasury = new ethers.Contract(compliantTreasuryAddress, CompliantTreasuryAbi, signer);
    const currencyAddress = addresses[chainId].getAddress(currency);
    try {
        const amount = ethers.parseUnits($('#transfer-amount-input').val(), decimals[currencyAddress]);
        const recipient = $('#invoicer-input').val();
        const args = [recipient, currencyAddress, amount];
        if (whitelisted) {
            if (currency != 'ETH') {
                const token = new ethers.Contract(currencyAddress, Erc20Abi, signer);
                await token.approve(compliantTreasuryAddress, amount);
            }
            const value = currency == 'ETH' ? amount : BigInt(0);
            await (await compliantTreasury.transfer(...args, { value })).wait();
        } else if (recipient == signer.address) { // This is a withdrawal
            const withdrawArgs = [currencyAddress, amount];
            // const functionSelector = compliantTreasury.interface.getFunction('withdraw', withdrawArgs).selector;
            // or: = "function withdraw(address currency, uint256 amount) virtual public";
            await validateCompliance(
                chainId,
                signer.address,
                compliantTreasuryAddress,
                0,
                compliantTreasury.interface.encodeFunctionData('withdraw', withdrawArgs),
                // or: { functionSelector, withdrawArgs },
                { amounts: [{ token: currencyAddress, value: amount }] }
            );
            await (await compliantTreasury.withdraw(...withdrawArgs)).wait();
            successPopup('Withdrawal completed.');
        } else { // This is a transfer
            // const functionSelector = compliantTreasury.interface.getFunction('transfer', args).selector;
            // or: = "function transfer(address recipient, address currency, uint256 amount) virtual public";
            const response = await validateCompliance(
                chainId,
                signer.address,
                compliantTreasuryAddress,
                0,
                compliantTreasury.interface.encodeFunctionData('transfer', args), // or: { functionSelector, args },
                {
                    wallets: [recipient],
                    amounts: [{ token: currencyAddress, value: amount }]
                }
            );
            console.log(response);
            if (response.result.status == "rejected") {
                console.log(response.result.reason);
                errorPopup(`Transfer rejected. ${response.result.reason}`);
            } else {
                try {
                    await (await compliantTreasury.transfer(...args)).wait();
                } catch (error) {
                    errorPopup(error.message);
                }
                const withdrawLink = `${window.location.origin}/withdraw?chain=${chainId}&currency=${currency}&amount=${ethers.formatUnits(amount, decimals[currencyAddress])}&sender=${signer.address}&recipient=${recipient}`;
                try {
                    navigator.clipboard.writeText(withdrawLink);
                    successPopup('Transfer completed.\nWithdraw link copied to clipboard.');
                } catch (error) {
                    warningPopup('Transfer completed.\nFailed to copy to clipboard: ' + error.message + '\nWithdraw link: ' + withdrawLink);
                }
            }
        }
    } catch (error) {
        errorPopup(error.message);
    }
    $('#transferModal').modal('hide');
    $('#transfer-button').prop('disabled', false);
    $('#transfer-button').html('Transfer');
    templateInjection();
}

async function deposit() {
    $('#deposit-button').prop('disabled', true);
    $('#deposit-button').html(loadingSpinner);
    const signer = await getSigner();
    const chainId = (await signer.provider.getNetwork()).chainId;
    const compliantTreasuryAddress = addresses[chainId].getAddress('compliantTreasury');
    const compliantTreasury = new ethers.Contract(compliantTreasuryAddress, CompliantTreasuryAbi, signer);
    const currencyAddress = addresses[chainId].getAddress(currency);
    try {
        const amount = ethers.parseUnits($('#receive-amount-input').val(), decimals[currencyAddress]);
        const value = currency == 'ETH' ? amount : BigInt(0);
        const args = [signer.address, currencyAddress, amount];
        const functionSelector = compliantTreasury.interface.getFunction('pay', args).selector;
        // or: = "function pay(address destination, address currency, uint256 amount) virtual public payable";
        if (currency != 'ETH') {
            const token = new ethers.Contract(currencyAddress, Erc20Abi, signer);
            await token.approve(compliantTreasuryAddress, amount);
        }
        const response = await validateCompliance(
            chainId,
            signer.address,
            compliantTreasuryAddress,
            value,
            { functionSelector, args }, // or: compliantTreasury.interface.encodeFunctionData('pay', args),
            { amounts: [{ token: currencyAddress, value: amount }] }
        );
        console.log(response);
        if (response.result.status == "rejected") {
            console.log(response.result.reason);
            errorPopup(`Transfer rejected. ${response.result.reason}`);
        } else {
            await (await compliantTreasury.pay(...args, { value })).wait();
            successPopup('Deposit completed.');
        }
    } catch (error) {
        console.error(error);
        errorPopup(error.message);
    }
    $('#receiveModal').modal('hide');
    $('#deposit-button').prop('disabled', false);
    $('#deposit-button').html('Deposit');
    templateInjection();
}

async function updateLink() {
    const invoicer = (await getSigner()).address;
    const amount = $('#receive-amount-input').val();
    const invoicerVal = invoicer ? `receiver=${invoicer}` : '';
    const amountVal = amount ? `amount=${amount}` : '';
    const currencyVal = currency ? `currency=${currency}` : '';
    const args = [invoicerVal, amountVal, currencyVal].filter(arg => arg).join('&');
    $('#payment-link').text(`${window.location.origin}/payment${args ? `?${args}` : ''}`);
}

function copyPaymentLink() {
    navigator.clipboard.writeText($('#payment-link').text());

    var paymentLinkControl = $('#payment-link-control').get(0);
    paymentLinkControl.style.transition = '';
    paymentLinkControl.style.setProperty('color', 'green', 'important');
    paymentLinkControl.style.opacity = '1';
    setTimeout(function() {
        paymentLinkControl.style.transition = 'color 1s, opacity 1s';
        paymentLinkControl.style.removeProperty('color');
        paymentLinkControl.style.opacity = '0.75';
    }, 50);
    setTimeout(function() {
        paymentLinkControl.style.transition = '';
    }, 600);
}

async function userPolicy() {
    const signer = await getSigner();
    const chainId = (await signer.provider.getNetwork()).chainId;
    const compliantTreasuryAddress = addresses[chainId].getAddress('compliantTreasury');

    validateUser(chainId, compliantTreasuryAddress).then(function(response) {
        console.log(response);
        if (response.error) {
            errorPopup(`Service error: ${response.error.message}. Please try again later.`);
            return;
        }
        switch (response.result.status) {
            case 'approved':
                successPopup('Your user profile is approved.');
                break;
            case 'approval_required':
                warningPopup('Your user profile requires approval to proceed, please try again later.');
                break;
            case 'rejected':
                errorPopup(`User profile rejected. ${response.result.reason}.`);
                break;
        }
    });
}

onWalletConnect(templateInjection);

$(document).ready(function() {
    window.userPolicy = userPolicy;
    window.setBackendUrl = setBackendUrl;
    window.setAuthUrl = setAuthUrl;
    window.setDevMode = setDevMode;
    $('#receive-amount-input').on('input', updateLink);
    $('#payment-link-control').click(copyPaymentLink);
    $('#transfer-button').click(transfer);
    $('#deposit-button').click(deposit);
    $('#invoicer-button').click(async function() {
        const signer = await getSigner();
        $('#invoicer-input').val(signer.address);
    });
    const searchParams = new URLSearchParams(window.location.search);
    const wallet = searchParams.has('wallet') ? searchParams.get('wallet') : 'metamask';
    providerInit(wallet);
});
